import React from "react";
import cookie from "react-cookies";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

import $ from "jquery";

import { FaCarAlt } from "react-icons/fa";

import Announcements from "./Announcements.jsx";
import Calendar from "./Calendar.jsx";

import { UserContext } from "../App.js";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendar_id: 0,
      announcements: [],
      announcements_i: {},
      cal_dropdown_open: false,
      selected_calendar: 1,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.handleBookVehicleClick = this.handleBookVehicleClick.bind(this);

    this._Calendar = React.createRef();
  }

  componentDidMount() {
    if (this.props.jwt) this.load();
    this.setState({ selected_calendar: cookie.load("calendar") || 1 });
  }

  load() {
    const postData = {
      action: "getAnnouncements",
      id: this.props.uid,
      jwt: this.props.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) {
          console.log(data.error);
          return false;
        }
        let announcements_i = {};
        let announcements_by_bookable = {};
        data.forEach((a) => {
          announcements_i[a.id] = a;
          if (a.bookables) {
            a.bookables.forEach((b) => {
              announcements_by_bookable[b] = announcements_by_bookable[b] || [];
              announcements_by_bookable[b].push(a);
            });
          }
        });
        this.setState({
          announcements: data,
          announcements_i: announcements_i,
          announcements_by_bookable: announcements_by_bookable,
        });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  toggleDropdown() {
    this.setState({ cal_dropdown_open: !this.state.cal_dropdown_open });
  }

  handleCalendarChange(e) {
    const calendar = e.target.value;
    this.setState({ selected_calendar: calendar });
    cookie.save("calendar", calendar);
    console.log("calendar change", calendar);
  }

  handleBookVehicleClick() {
    this._Calendar.current.handleDateClick({
      date: new Date(),
    });
  }

  render() {
    return (
      <div className="animated fadeIn container">
        <Row style={{ marginTop: 30, marginBottom: 0 }}>
          <Col xs={6}>
            {this.props.calendars && this.props.calendars[this.state.selected_calendar] && (
              <Dropdown isOpen={this.state.cal_dropdown_open} toggle={this.toggleDropdown} size="md" className="mb-3">
                <DropdownToggle caret color="dark">
                  {this.props.calendars[this.state.selected_calendar].calendar_title}
                </DropdownToggle>
                <DropdownMenu>
                  {Object.keys(this.props.calendars).map((key) => {
                    return (
                      <DropdownItem value={key} key={key} onClick={this.handleCalendarChange}>
                        {this.props.calendars[key].calendar_title}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
          <Col xs={6}>
            <Button className="float-right" color="primary" onClick={this.handleBookVehicleClick}>
              <FaCarAlt style={{ marginRight: 10 }} />
              Book Vehicle
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Announcements
              announcements={this.state.announcements}
              announcements_i={this.state.announcements_i}
              selected_calendar={this.state.selected_calendar}
            />
          </Col>
        </Row>

        <Row xs={12}>
          <Col xs={12}>
            {this.props.calendars && this.state.selected_calendar && (
              <UserContext.Consumer>
                {(user) => (
                  <span>
                    {user.id && (
                      <Calendar
                        calendars={this.props.calendars}
                        selected_calendar={this.state.selected_calendar}
                        announcements_i={this.state.announcements_i}
                        announcements_by_bookable={this.state.announcements_by_bookable}
                        user={user}
                        uid={this.props.uid}
                        jwt={this.props.jwt}
                        ref={this._Calendar}
                      />
                    )}
                  </span>
                )}
              </UserContext.Consumer>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
