import React, {useEffect, useState, useRef} from "react"
import cookie from "react-cookies"
import {FaRegTrashAlt} from 'react-icons/fa'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import {api} from "../shared/functions/api"
import {renderToString} from "react-dom/server"
import moment from "./Calendar"
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

const VehicleAvails = ({user}) => {
    const calendar = useRef()
    const [vehicleAvails, setVehicleAvails] = useState([])

    useEffect(() => {
    if(!user) return;
        fetchAvails()
    }, [user])

    const updateAvail = async (eventDropObj) => {
        const event = eventDropObj.event
        let originalEventObj = vehicleAvails.find(x => x.id == event.id)

        // can't save events that span multiple dates
        if (event.start.getDay() != event.end.getDay() && !endsOnNextMidnight(event)){
            fetchAvails();
            return;
        }

        if (eventDropObj.newResource) {
            originalEventObj.vehicle_id = eventDropObj.newResource.id
        }

        originalEventObj.daysOfWeek = event.start.getDay()
        originalEventObj.startTime = event.start.toTimeString().split(' ')[0]
        originalEventObj.endTime = event.end.toTimeString().split(' ')[0]

        postAvail(originalEventObj)
    }

    const newAvail = async (dragEventObj) => {

        // can't save events that span multiple dates
        if (dragEventObj.start.getDay() != dragEventObj.end.getDay() && !endsOnNextMidnight(dragEventObj)){
            return;
        }

        const event = {
            vehicle_id: dragEventObj.resource.id,
            user_id: user.id,
            daysOfWeek: dragEventObj.start.getDay(),
            startTime: dragEventObj.start.toTimeString().split(' ')[0],
            endTime: dragEventObj.end.toTimeString().split(' ')[0]
        }

        postAvail(event)
    }

    const postAvail = async (event) => {
        // adjust dates that end at midnight so they'll show right on calendar
        if (event.endTime == '00:00:00')
            event.endTime = '23:59:59'

        // and fix them if they get edited to end at a different time
        if (event.endTime.endsWith('59:59') && !event.endTime.startsWith('23'))
            event.endTime = (Number(event.endTime.slice(0,2))+1)+':00:00';
        if (event.endTime.endsWith('29:59'))
            event.endTime = event.endTime.slice(0,2)+':30:00';

        let r = await api(`vehiclesavailability/add`, 'POST', event)
        fetchAvails()
    }

    const fetchAvails = async () => {
        let vehicleAvails = []

        for (var vehicle of user.vehicles) {
            const vehicleAvailObjectResponse = await api(`vehiclesavailability/get/${vehicle.id}`)

            for (var vehicleAvail of vehicleAvailObjectResponse) {

                // don't read junk data
                if (vehicleAvail.startTime >= vehicleAvail.endTime)
                    continue

                vehicleAvail.durationEditable = true
                vehicleAvail.startEditable = true
                vehicleAvails.push(vehicleAvail)
            }
        }

        setVehicleAvails(vehicleAvails)
    }

    const formatHeadings = (date) => {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return weekdays[date.getDay()]
    }

    const formatVehicles = (user) => {
    if(!user || !user.vehicles) return;
        let vehicleFormatArray = []
        for (var vehicle of user.vehicles) {
            vehicleFormatArray.push({id: vehicle.id, title: vehicle.nickname})
        }

        return vehicleFormatArray
    }

    const eventRender = (info) => {
        const buttonStyle = {
            backgroundColor: 'white',
            position: 'absolute',
            bottom: 5,
            right: 5,
            width: 12,
            height: 12,
            zIndex: 10,
            padding: 5,
            borderRadius: '50%',
            cursor: '',
            display: 'flex'
        }
        const name = renderToString(<div id='eventRemove' style={buttonStyle}><FaRegTrashAlt
            style={{pointerEvents: 'none'}}/></div>)
        info.el.innerHTML = info.el.innerHTML + name
        return info.el
    }

    const removeAvail = async (event) => {
        const element = event.jsEvent.srcElement
        if (element.id !== 'eventRemove') return

        const deleteResponse = await api(`vehiclesavailability/delete/${event.event.id}`, 'DELETE')
        fetchAvails()
    }

    const endsOnNextMidnight = (event) =>{
        return  (event.start.getDay()+1) % 7 == event.end.getDay() % 7
                && event.end.toTimeString().split(' ')[0] == '00:00:00'
    }
    
    return (
        <div className="animated fadeIn container">
                <Row>
                    <Col xs={12}>
                        <Card className="card-accent-primary border-primary mt-4">
                            <CardHeader className="bg-light-blue">
                                <strong>Vehicle Availability - Recurring Weekly</strong>
                            </CardHeader>
                            <CardBody>
                                {user &&
                                <FullCalendar
                                    timeZone='local'
                                    selectable={true}
                                    editable={true}
                                    defaultView='resourceTimeGridWeek'
                                    plugins={[resourceTimeGridPlugin, interactionPlugin, momentTimezonePlugin]}
                                    select={(event) => newAvail(event)}
                                    eventDrop={(event) => updateAvail(event)}
                                    eventResize={(event) => updateAvail(event)}
                                    eventRender={(event) => eventRender(event)}
                                    eventClick={(event) => removeAvail(event)}
                                    events={vehicleAvails}
                                    columnHeaderText={(date) => formatHeadings(date)}
                                    datesAboveResources={true}
                                    resources={formatVehicles(user)}
                                    eventOverlap={false}
                                    header={false}
                                    schedulerLicenseKey='0911742788-fcs-1573055291'
                                    ref={calendar}
                                />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </div>
        )
    
}
export default VehicleAvails
