import moment from "moment";

/*
	Custom class for limiting dates and times in the popup date / time picker
	based on available time slots and max booking length.

	Kevin Suggitt  kschaos@gmail.com  11/2019
*/

class Picker {
  constructor(props) {
    this.start = moment(props.start);
    this.end = moment(props.end);
    this.events = props.events;
    this.vehicleAvails = props.vehicleAvails;
    this.existing_event_id = props.existing_event_id;
    this.max_booking_length = props.max_booking_length;
    this.max_future_booking = props.max_future_booking;

    this.result = {};
  }

  calculate(exclude = true) {
    let toExclude = [];
    let nextEvent = null;
    let overlappingEvent = null;
    let eventsAvails;

    if (exclude) {
      eventsAvails = this.events;
      console.log("excluding", this.events);
    } else {
      eventsAvails = this.getAvails();
      // console.log('including', eventsAvails)
    }
    console.log("Avaliable events", eventsAvails);
    for (var i in eventsAvails) {
      let event = eventsAvails[i];

      // events starting on the same day
      if (moment(event.start).isSame(this.start, "day") && (!this.existing_event_id || event.id !== this.existing_event_id)) {
        toExclude.push(event);
      }

      // events ending on the same day but starting on a different day
      if (!moment(event.start).isSame(this.start, "day") && moment(event.end).isSame(this.start, "day")) overlappingEvent = event;

      // the next event
      if (!nextEvent && moment(event.start).isAfter(this.start) && (!this.existing_event_id || event.id !== this.existing_event_id))
        nextEvent = event;
    }

    this.excludeStartTimes(toExclude, overlappingEvent);
    //console.log("next event", nextEvent);
    this.excludeEndTimes(nextEvent);

    this.result.include_dates_end = this.includeEndDates(nextEvent);
    return this.result;
  }

  getAvails() {
    let availEvents = [];

    if (this.vehicleAvails.length === 0) return [];

    for (var avail of this.vehicleAvails) {
      const availStartArray = avail.startTime.split(":");
      const availEndArray = avail.endTime.split(":");
      const availDOW = parseInt(avail.daysOfWeek);

      const eventStart = moment(this.start);
      const eventEnd = moment(this.end);
      const eventStartDOW = eventStart.day();

      if (eventStartDOW === availDOW) {
        let vehicleStart = eventStart.hour(availStartArray[0]).minute(availStartArray[1]);
        let vehicleEnd = eventEnd.hour(availEndArray[0]).minute(availEndArray[1]);

        availEvents.push({
          id: avail.vehicle_id,
          start: vehicleStart.format("YYYY-MM-DD HH:mm:ss").toString(),
          end: vehicleEnd.format("YYYY-MM-DD HH:mm:ss").toString(),
        });
      }
    }
    console.log("Avaliable events 1", availEvents);
    return availEvents;
  }

  excludeStartTimes(events, overlappingEvent) {
    let times = [];
    let timesCopy = [];
    let timesEnd = [];
    let datesEnd = [];

    // event that ends today but starts before today
    if (overlappingEvent) {
      let time = moment(this.start).set("hour", 0).set("minute", 0);
      while (time.isBefore(moment(overlappingEvent.end))) {
        times.push(time.toDate());
        time.add(15, "minutes");
      }
    }

    events.forEach((event) => {
      let time = moment(event.start);
      let timeCopy = moment(event.start).add(15, "minutes");
      let event_start = moment(event.start);
      let event_end = moment(event.end);
      let selected_start = moment(this.start);
      // Start times:
      // add times of this event to the exclude array
      while (time.isBefore(event_end)) {
        if (!times.includes(time.toDate())) times.push(time.toDate());
        if (!timesCopy.includes(timeCopy.toDate())) timesCopy.push(timeCopy.toDate());
        time.add(15, "minutes");
        timeCopy.add(15, "minutes");

        // stop is cycling around and blocking out today's earlier hours..
        if (!time.isSame(moment(this.start), "day")) break;
      }
    });

    this.result.exclude_times = times;
    this.result.exclude_times_end = timesEnd.concat(timesCopy);
  }

  excludePriorTimes(times) {
    // exclude all times before selected start
    times = times || [];
    let time = moment(this.start);
    let selected_start = moment(this.start);
    time.hour(0).minute(0);
    while (time.isBefore(selected_start)) {
      if (!times.includes(time.toDate())) times.push(time.toDate());
      time.add(15, "minutes");
    }
    return times;
  }

  includeEndDates(nextEvent) {
    /*
			limit is set to:
			- nextEvent.start if exists and is within 6 month limit AND within 4 month duration
			- else, max_booking_length if that is withing 6 month limit
			- else, 6 month limit
		*/

    let limit = null;
    const nextStart = nextEvent ? moment(nextEvent.start) : null;
    const future_cutoff = moment().add(this.max_future_booking, "months"); // eg 6 months from NOW
    const duration_limit = moment(this.start).add(this.max_booking_length, "days"); // eg 4 months from start date

    if (nextEvent && nextStart.isSameOrBefore(future_cutoff) && nextStart.isSameOrBefore(duration_limit)) limit = nextStart;
    else if (duration_limit.isSameOrBefore(future_cutoff)) limit = duration_limit;
    else limit = future_cutoff;

    let dates = [];
    let time = moment(this.start);

    while (time.isSameOrBefore(limit, "day")) {
      dates.push(time.toDate());
      time.add(1, "day");
    }
    return dates;
  }

  excludeEndTimes(nextEvent) {
    const selected_start = moment(this.start);
    const selected_end = moment(this.end);

    let times = this.result.exclude_times_end;

    let altered_end_time = this.end;

    if (selected_start.isSame(moment(this.end), "day")) times = this.excludePriorTimes();
    if (nextEvent) {
      const event_start = moment(nextEvent.start);
      let time = null;
      if (selected_start.isSame(selected_end)) {
        time = moment(this.start);
      } else {
        // const time = moment(nextEvent.start);
        time = moment(this.end);
        time.set("hour", 0);
      }
      if (event_start.isSame(selected_end, "day")) {
        // exclude times before that event start
        for (let i = 0; i < 96; i++) {
          if (time.isAfter(event_start)) {
            times.push(time.toDate());
          }
          if (time.isSame(event_start, "minute") && selected_end.isAfter(time)) {
            // this was setting end time to start time of next event before I made this a
            // separate class; now it sets it to 00:00. not sure which is better

            altered_end_time = time;
          }
          time.add(15, "minutes");
          if (!time.isSame(event_start, "day")) break;
        }
      }
    }

    this.result.exclude_times_end = times;
    this.result.date_end = altered_end_time;
  }
}

export default Picker;
