import React, { useState } from 'react';

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { FaEye } from 'react-icons/fa';


const Announcements = (p) => {

  const [selected, setSelected] = useState(null);

  return (
    <Card className="mt-4 mg-12" >
      <CardHeader>
        Announcements         
      </CardHeader>
      <CardBody className="">
        <Row>
        <div className="card-columns">          
        {Object.keys(p.announcements).map((e, key) => {
          const a = p.announcements[key];
          if(parseInt(a.calendar_id) == p.selected_calendar)
            return(
             
                <Card className="announcement-card" key={key}>
                  <CardHeader> 
                    <Button size='sm' color="light" className="float-right announcement-button" data-id={a.id} onClick={() => setSelected(a.id)}>
                      <FaEye/>
                    </Button>
                    {a.title}
                  </CardHeader>
                </Card>
             
            )
        })}
        </div>       
        </Row>
      </CardBody>
      {selected && p.announcements_i[selected] && (
      <AnncModal 
        a={p.announcements_i[selected]}
        className='primary'
        hide={() => setSelected(null)}
      />
    )}
    </Card>
  )
}



const AnncModal = (props) => {
  const{ className, a, hide } = props;
  return(
     <Modal isOpen={true} className={className}>
        <ModalHeader><strong>{a.title}</strong></ModalHeader>
        <ModalBody>{a.text}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={hide}>Okay</Button>{' '}
        </ModalFooter>
      </Modal>
  )
}

export default Announcements;