import cookie from "react-cookies"

export const api = async (route, method = 'GET', payload) => {
    const url = `${cookie.load('url')}admin/cake210/${route}`

    return await fetch(url, {
        method: method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + cookie.load('jwt'),
        },
        body: JSON.stringify(payload)
    }).then(response => response.json());
}
