import React from "react";
import $ from "jquery";
import cookie from "react-cookies";
import { Table, Spinner } from "reactstrap";

const schema = {
  created: "Date",
  operation_text: "Action",
  modified_by: "By",
  booking_for: "Is for",
  amntTagged: "Hours in 2 day window",
  vehicle: "Vehicle",
  starttime: "Start time",
  endtime: "End time",
};

class EventLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log: null,
    };
  }

  componentDidMount() {
    this.props.event && this.load();
  }

  load() {
    const postData = {
      action: "getEventLog",
      eventid: this.props.event.id,
      id: this.props.uid,
      jwt: this.props.jwt,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) {
          console.log(data.error);
          return false;
        }
        let items = [];
        data.forEach((item) => {
          item.vehicle = this.props.bookable.nickname;
          items.push(item);
        });

        this.setState({
          log: items,
        });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  render() {
    return (
      <span>
        {this.state.log && (
          <Table>
            <thead>
              <tr>
                {Object.keys(schema).map((e, key) => {
                  const value = schema[e];
                  return <th key={key}>{value}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.state.log).map((e, key) => {
                const item = this.state.log[e];
                return (
                  <tr key={key}>
                    {Object.keys(schema).map((i, key2) => {
                      return <td key={key2}>{item[i]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {!this.state.log && <Spinner animation="border" role="status" color="dark" />}
      </span>
    );
  }
}

export default EventLog;
