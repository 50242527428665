import React from "react";
import $ from "jquery";
import cookie from "react-cookies";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  InputGroupText,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap";

import { FaCheckCircle } from "react-icons/fa";

import { renderToString } from "react-dom/server";

const PROVINCES = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  NT: "Northwest Territories",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Québec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      user_save_success: null,
      new_password_verified: false,
      saveSuccess: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ user: props.user });
    if (props.saveSuccess == true) {
      this.setState({ saveSuccess: true, saving: false }, () => {
        setTimeout(() => {
          this.setState({ saveSuccess: false });
        }, 2000);
      });
    }
  }

  handleChange(e) {
    const field = e.target.id;
    let user = this.state.user;

    if (e?.target?.id === "notification_email" || e?.target?.id === "notification_text") {
      if (!user.notifications) {
        user.notifications = [];
      }
      user.notifications[e.target.id] = e.target.checked;
    } else {
      user[field] = e.target.value;
    }

    if (field == "password1" || field == "password2") this.verifyPassword();

    this.setState({ user: user });
  }

  handleSave() {
    this.setState({ saving: true });
    this.props.handleSave(this.state.user);
  }

  verifyPassword() {
    let { password_validation_error, user } = this.state;
    let msg = null,
      new_password_verified = false;
    if (!user.password1 || !user.password2) msg = "Please enter a new password in both boxes";
    else if (user.password1 != user.password2) msg = "Passwords do not match";
    else if (user.password1.length < 7) msg = "Please use a stronger password of at least 7 characters";
    else new_password_verified = true;
    this.setState({ new_password_verified, password_validation_error: msg });
  }

  handleChangePassword() {
    const postData = {
      action: "savePassword",
      id: this.props.uid,
      jwt: this.props.jwt,
      password: this.state.user.password1,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) console.log(data.error);
        if (data.result == "success") {
          this.setState({ password_saved: true }, () => {
            setTimeout(() => {
              this.setState({ password_saved: false });
            }, 3000);
          });
        }
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  render() {
    //console.log("user", this.state.user);
    const user = this.state.user;
    if (user) {
      //console.log("notifications", user.notifications);
      return (
        <div className="animated fadeIn container">
          <Row>
            <Col xs="12" sm="10">
              <Card className="mt-4">
                <CardHeader className="bg-dark text-white">
                  <strong>My Account</strong>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="name">Display Name</Label>
                        <Input type="text" id="displayname" placeholder="Enter your display name" value={user.displayname} onChange={this.handleChange} required />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="address1">Address 1</Label>
                        <Input type="text" id="address1" placeholder="Your address" value={user.address1} onChange={this.handleChange} required />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="address2">Address 2</Label>
                        <Input type="text" id="address2" placeholder="" value={user.address2} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="city">city</Label>
                        <Input type="text" id="city" placeholder="City" required value={user.city} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="province">Province</Label>
                        <Input type="select" id="province" default="BC" required value={user.province} onChange={this.handleChange}>
                          {Object.keys(PROVINCES).map((key) => {
                            return (
                              <option value={key} key={key}>
                                {PROVINCES[key]}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="postalcode">Postal Code</Label>
                        <Input type="text" id="postalcode" placeholder="Postal Code" required value={user.postalcode} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="phone">Phone</Label>
                        <Input type="text" id="phone" placeholder="Your phone number" required value={user.phone} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input type="text" id="email" placeholder="Your email" required value={user.email} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col xs={3}>
                      {!this.state.saving && (
                        <FormGroup>
                          <Button color="primary" onClick={this.handleSave}>
                            Save Changes
                          </Button>
                        </FormGroup>
                      )}
                      {this.state.saving && <Spinner color="primary" />}
                    </Col>
                    <Col xs={9}>
                      {this.state.saveSuccess && (
                        <Alert color="success" className="pull-right">
                          <FaCheckCircle /> Changes saved successfully
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>

              {user.vehicles !== undefined && user.vehicles.length > 0 && (
                <Card className="mt-4">
                  <CardHeader className="bg-dark text-white">
                    <strong>Notification Options</strong>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Input type="checkbox" id="notification_email" value="1" onChange={this.handleChange} defaultChecked={user?.notifications?.notification_email === "true"} style={{ margin: 0, marginRight: 10, position: "relative" }} />
                          <Label htmlFor="notification_email">Email me when someone books my vehicle(s)</Label>
                        </FormGroup>
                        <FormGroup>
                          <Input type="checkbox" id="notification_text" value="1" onChange={this.handleChange} defaultChecked={user?.notifications?.notification_text === "true"} style={{ margin: 0, marginRight: 10, position: "relative" }} />
                          <Label htmlFor="notification_text">Text me when someone books my vehicle(s)</Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col xs={3}>
                        {!this.state.saving && (
                          <FormGroup>
                            <Button color="primary" onClick={this.handleSave}>
                              Save Changes
                            </Button>
                          </FormGroup>
                        )}
                        {this.state.saving && <Spinner color="primary" />}
                      </Col>
                      <Col xs={9}>
                        {this.state.saveSuccess && (
                          <Alert color="success" className="pull-right">
                            <FaCheckCircle /> Changes saved successfully
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs="12" sm="10">
              <Card className="card-accent-primary border-primary mt-4">
                <CardHeader className="bg-light-blue">
                  <strong>Change Password</strong>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="password1">New Password</Label>
                        <Input type="password" id="password1" placeholder="Enter your new password" required onChange={this.handleChange} autoComplete="new-password" />
                      </FormGroup>
                    </Col>

                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="password2">Confirm Password</Label>
                        <Input type="password" id="password2" placeholder="Confirm Password" required onChange={this.handleChange} autoComplete="new-password" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter>
                  {this.state.password_validation_error && <Alert color="warning">{this.state.password_validation_error}</Alert>}
                  <FormGroup>
                    <Button color="info" onClick={() => this.handleChangePassword()} disabled={!this.state.new_password_verified}>
                      Change Password
                    </Button>
                  </FormGroup>
                  {this.state.password_saved && <Alert color="success">Your new password has been saved. Please be sure to write it down or save it somewhere safe.</Alert>}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  }
}

export default Account;
