import React from "react";
import $ from "jquery";
import cookie from "react-cookies";
import TripEstimator from "./TripEstimator.jsx";
import EventLog from "./EventLog.jsx";
import MiniMap from "./MiniMap.jsx";

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  FaInfoCircle,
  FaCarAlt,
  FaCheckCircle,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaExclamationTriangle,
  FaTimesCircle,
  FaUser,
  FaLock,
  FaChevronLeft,
  FaList,
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

import { AppSwitch } from "@coreui/react";

import Picker from "../classes/Picker.jsx";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

const MAX_BOOKING_LENGTH = 120; // days
const MAX_FUTURE_BOOKING = 6; // months
const MSG_RECURRING_OVERLAP = "Some of your recurring bookings overlap existing bookings. Either change the time/date or turn off recurrence.";
const CREATE_BOOKING_LIMIT = -0.5; // hours
const CANCEL_BOOKING_LIMIT = 48; // hours

const mapStyles = {
  width: "80%",
  height: "100%",
};

class BookingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingModalOpen: false,
      display_mode: "main", // main, log
      date_start: new Date(),
      date_end: new Date(),
      exclude_times_start: [],
      selected_bookable: null,
      notes: "",
      kms: 0,

      conflict: false,
      conflict_type: null,

      duration: 0,

      dates_valid: false,

      validation_messages: [],
      instruction_messages: [],
      include_times: [],
      exclude_times: [],
      exclude_times_end: [],
      showMap: false,
      recurring: false,
      recurring_weeks: 2,
      comments: "",
      complaint_saved: false,
      selected_user: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDateChangeRaw = this.handleDateChangeRaw.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleRecurring = this.toggleRecurring.bind(this);
    this.handleRecurringChange = this.handleRecurringChange.bind(this);
    this.handleChangeComments = this.handleChangeComments.bind(this);
    this.handleSaveComplaint = this.handleSaveComplaint.bind(this);
    this.handleCancelBooking = this.handleCancelBooking.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log("booking", nextProps);
    if (nextProps.modalState !== "notices") {
      this.setState(
        {
          date_start: moment(nextProps.selected_date).toDate(),
          date_end: moment(nextProps.selected_date).toDate(),
          selected_bookable: nextProps.selected_bookable,
        },
        () => {
          if (nextProps.selected_bookable || this.state.selected_bookable) this.setExcludedTimes();
          this.validate();
        }
      );
    }

    if (nextProps.editingEvent && !this.props.editingEvent) this.editEvent(nextProps);

    // closing modal: clear imputs
    if (!nextProps.bookingModalOpen && !nextProps.editingEvent) {
      this.setState({
        kms: 0,
        notes: "",
        recurring: false,
        recurring_weeks: "2",
        selected_user: this.props.user,
        display_mode: "main",
      });
    }

    if (nextProps.user && !this.state.selected_user) this.setState({ selected_user: nextProps.user });
  }

  handleChange(e) {
    let target = e.target;
    if (target.id === "bookable-chooser") {
      if (target.value === "") {
        return;
      }
      this.setState({ selected_bookable: this.props.bookables[target.value] }, () => {
        this.setExcludedTimes();
        this.validateDates();
      });
    } else
      this.setState({ [target.id]: target.value }, () => {
        if (target.id === "kms") this.validate();
      });
  }

  changeDisplayState(new_state) {
    this.setState({ display_mode: new_state });
  }

  handleChangeComments(e) {
    this.setState({ comments: e.target.value });
  }

  handleStartDateChange(date) {
    this.setState({ date_start: date, date_end: date }, () => {
      // this.validateDates();
      this.validate();
      this.setExcludedTimes(date);
    });
  }

  handleEndDateChange(date) {
    this.setState({ date_end: date }, () => {
      // this.validateDates();
      this.validate();
      this.setExcludedTimes(date);
    });
  }

  handleCancelBooking() {
    const start = moment(this.state.date_start);
    const cancelation_type = start.diff(moment(), "hours") > CANCEL_BOOKING_LIMIT ? 1 : 2;

    if (!window.confirm("Are you sure you want to cancel this booking? There is no way to undo this.")) return false;
    this.props.cancelBooking(this.props.editingEvent.id, cancelation_type);
  }

  clearUser() {
    this.setState({ selected_user: null }, () => this.validate());
    this._userchooser.clear();
    this._userchooser.focus();
  }

  handleSave() {
    let start_time = this.roundTime(moment(this.state.date_start), moment.duration(15, "minutes"), "floor");
    let end_time = this.roundTime(moment(this.state.date_end), moment.duration(15, "minutes"), "floor");
    const future_cutoff = moment().add(MAX_FUTURE_BOOKING, "months");
    const iterations = this.state.recurring ? parseInt(this.state.recurring_weeks) : 1;
    let fail = false;

    const data = {
      events: [],
      uid: this.state.selected_user.id,
      editor_id: this.props.user.id,
    };

    for (let x = 0; x < iterations; x++) {
      if (start_time.isAfter(future_cutoff)) {
        alert("Sorry, you cannot book more than " + MAX_FUTURE_BOOKING + " months in the future.");
        fail = true;
        break;
      }

      if (!this.validateOverlapping(start_time, end_time)) {
        alert(
          "This overlaps an existing booking! Please close this dialog, then go to this day on the main calendar so you can see what time slots are available."
        );
        fail = true;
        break;
      }

      if (!this.validateAvailability(start_time, end_time)) {
        alert("This vehicle isn't available at the selected times! Please review the calendar to see what times slots are available.");
        fail = true;
        break;
      }

      if (start_time.isSame(end_time)) {
        alert("Your start time and end time cannot be the same.");
        fail = true;
        break;
      }

      data.events.push({
        starttime: start_time.format("YYYY-MM-DD HH:mm:ss"),
        endtime: end_time.format("YYYY-MM-DD HH:mm:ss"),
        bookableobject: this.state.selected_bookable.id,
        vehicle_used_id: this.state.selected_bookable.vehicle_id,
        subject: this.state.notes,
        estimated_kms: this.state.kms,
      });
      start_time.add(1, "week");
      end_time.add(1, "week");
    }
    if (!fail) this.props.handleBook(data);
  }

  validateOverlapping(start_time, end_time) {
    const events = this.props.eventsByResource[this.state.selected_bookable.id] || [];
    if (this.props.editingEvent) {
      const editing_id = this.props.editingEvent.id;
      return !events.some((event) => event.id !== editing_id && moment(event.start).isBefore(end_time) && moment(event.end).isAfter(start_time));
    } else return !events.some((event) => moment(event.start).isBefore(end_time) && moment(event.end).isAfter(start_time));
  }

  validateAvailability(start_time, end_time) {
    let events = this.props.vehicleAvails[this.state.selected_bookable.vehicle_id];
    //console.log("events", events);
    if (events === undefined) return true;

    // per day in booking, check that avails cover the entire requested period
    // ( for now, avails can't transcend a day and avails ending at midnight have to end a second early )
    for (var i = moment(start_time); i.isBefore(end_time); i.add(1, "days")) {
      var day_start_time = i.format("HH:mm:ss");
      var day_end_time = i.diff(end_time, "days") == 0 ? end_time.format("HH:mm:ss") : "23:59:59";

      var valid_thru = day_start_time;

      var e = events
        .filter((ev) => ev.daysOfWeek == i.day())
        .sort((a, b) => b.startTime - a.startTime)
        .forEach(function (ev) {
          if (ev.startTime <= valid_thru && ev.endTime > valid_thru) valid_thru = ev.endTime;
        });
      //console.log("valid_thru < day_end_time", valid_thru, day_end_time);
      if (valid_thru < day_end_time) return false;

      i.set("hour", 0).set("minute", 0).set("second", 0);
    }

    return true;
  }
  /*
  validateAvailability(startTime, endTime) {

    if (!startTime || !endTime) {
      throw new Error('Start and end time are required');
    }
  
    const events = this.props.vehicleAvails[this.state.selected_bookable.vehicle_id];
  
    // Check each day in the time range
    return Array.from({length: moment(endTime).diff(startTime, 'days')}, (_, i) => i)
      .every(dayOffset => this.checkAvailabilityForDay(startTime, endTime, dayOffset, events));
  
  }
  
   checkAvailabilityForDay(startTime, endTime, dayOffset, events) {

    const dayStart = moment(startTime).add(dayOffset, 'days');
    const dayEnd = moment(endTime).add(dayOffset, 'days');
  
    let validThru = dayStart.format('HH:mm:ss');
  
    // Filter to events on this day
    const daysEvents = events.filter(e => e.daysOfWeek === dayStart.day());
  
    // Check if any event conflicts with the time range 
    return !daysEvents.some(event => {
      if (event.startTime <= validThru && event.endTime > validThru) {
        validThru = event.endTime;
        return true; // conflict found
      }
      return false; // no conflict
    });
  
  }
*/
  roundTime(date, duration, method) {
    return moment(Math[method](+date / +duration) * +duration);
  }

  handleSaveComplaint() {
    const postData = {
      action: "saveComplaint",
      id: this.props.uid,
      jwt: this.props.jwt,
      comment: this.state.comments,
      bookable: this.state.selected_bookable.id,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (data.error) console.log("Api postData", data.error);
        if (data.result === "success") {
          this.setState({ complaint_saved: true }, () => {
            setTimeout(() => {
              this.setState({ complaint_saved: false, comment: "" }, () => {
                this.props.handleCancel();
              });
            }, 3000);
          });
        }
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  handleDateChangeRaw(e) {
    // workaround so mobile keyboard doesn't show
    e.preventDefault();
  }

  handleFocus(e) {
    e.target.select();
  }

  handleRecurringChange(e) {
    this.setState({ recurring_weeks: e.target.value }, () => {
      this.validateRecurrences();
    });
  }

  toggleMap() {
    this.setState({ showMap: !this.state.showMap });
  }

  toggleRecurring() {
    this.setState({ recurring: !this.state.recurring }, () => {
      this.validateRecurrences();
    });
  }

  validateRecurrences() {
    let validation_messages = [];
    let fail = false;
    let date_start = moment(this.state.date_start);
    let date_end = moment(this.state.date_end);

    if (this.state.recurring) {
      let recurrences = parseInt(this.state.recurring_weeks) - 1; // don't include first booking

      for (let r = 1; r <= recurrences; r++) {
        date_start.add(1, "week");
        date_end.add(1, "week");
        const booking = moment.range(moment(date_start), moment(date_end));
        fail = fail || this.overlapsExisting(booking);
      }

      if (fail) {
        validation_messages.push(MSG_RECURRING_OVERLAP);
      }
    }

    this.setState({ validation_messages });
  }

  overlapsExisting(booking) {
    let existings = this.props.eventsByResource[parseInt(this.state.selected_bookable.id)];
    let fail = false;
    existings.forEach((event) => {
      const range = moment.range(moment(event.start), moment(event.end));
      // console.log(booking.start.format("MM/DD-HH:mm"), booking.end.format("MM/DD-HH:mm"), '--', range.start.format("MM/DD-HH:mm"), range.end.format("MM/DD-HH:mm") );
      if (range.overlaps(booking)) fail = true;
    });

    return fail;
  }

  getMemberType() {
    const types = {
      1: "full",
      0: "casual",
    };
    let type = null;
    if (this.state.selected_user) type = types[this.state.selected_user.is_member];
    else type = types[this.props.user.is_member];
    return type;
  }

  editEvent(props) {
    // editing existing  event
    const event = props.editingEvent;
    this.setState({
      date_start: moment(event.start).toDate(),
      date_end: moment(event.end).toDate(),
      kms: event.estimated_kms || 0,
      selected_bookable: this.props.bookables[event.resourceId],
      selected_user: this.props.users.find((user) => user.id == event.uid),
      notes: event.subject,
      recurring: false,
      recurring_weeks: "2",
    });
  }

  validate() {
    const { selected_user, kms, recurring, date_start, date_end } = this.state;
    const { editingEvent } = this.props;
    const start = moment(date_start);
    const end = moment(date_end);
    let validation_messages = [];
    let instruction_messages = [];
    const is_admin = this.props.user.permission.includes("ADMIN_USER");

    let duration = end.diff(start, "minutes");

    const hours_till_start = start.diff(moment(), "hours");
    const minutes_till_start = start.diff(moment(), "minutes");

    let dates_valid = true;
    if (editingEvent) dates_valid = duration > 0;
    else dates_valid = duration > 0 && hours_till_start > CREATE_BOOKING_LIMIT;

    if (!selected_user) validation_messages.push("Please choose a user for this booking");

    if (!editingEvent && !is_admin && minutes_till_start < 0) validation_messages.push("A booking cannot begin in the past");

    if (!dates_valid && !is_admin) validation_messages.push("Please choose the start and end time for your booking");

    if (!kms || kms === 0) instruction_messages.push("Enter your estimated kilometers to see a cost estimate");

    if (hours_till_start < CANCEL_BOOKING_LIMIT)
      instruction_messages.push(
        " NOTE: If you cancel or shorten this booking you will need to pay for any booked time that is not subsequently booked by another member."
      );

    this.setState({ duration, dates_valid, validation_messages, instruction_messages }, () => {
      if (recurring && dates_valid) this.validateRecurrences();
    });
  }

  validateDates() {
    let validation_messages = [];
    let events = this.props.eventsByResource[this.state.selected_bookable.id];
    for (var i in events) {
      let event = events[i];

      // validate
      if (
        moment(this.state.date_start).isBetween(event.start, event.end, "minute") ||
        moment(this.state.date_start).isSame(event.start, event.end, "minute")
      ) {
        validation_messages.push("Selected times overlap an existing booking. Choose a different vehicle and/or start/end time.");
      } else if (moment(this.state.date_end).isBetween(event.start, event.end)) {
        validation_messages.push("Overlaps existing booking");
      }

      if (moment(event.start).isBetween(moment(this.state.start_date), moment(this.state.end_date))) {
        validation_messages.push("Overlaps existing booking");
      }
    }

    this.setState({ validation_messages });
  }

  setExcludedTimes(date) {
    let vehicleAvails = this.props.vehicleAvails[this.state.selected_bookable.vehicle_id];
    if (vehicleAvails === undefined) vehicleAvails = [];
    let events = this.props.eventsByResource[this.state.selected_bookable.id];
    let editing_event_id = this.props.editingEvent ? this.props.editingEvent.id : null;

    let p = new Picker({
      start: this.state.date_start,
      end: this.state.date_end,
      events: events,
      vehicleAvails: vehicleAvails,
      existing_event_id: editing_event_id,
      max_booking_length: MAX_BOOKING_LENGTH,
      max_future_booking: MAX_FUTURE_BOOKING,
    });

    const d = p.calculate();

    let incl = new Picker({
      start: this.state.date_start,
      end: this.state.date_end,
      events: events,
      vehicleAvails: vehicleAvails,
      existing_event_id: editing_event_id,
      max_booking_length: MAX_BOOKING_LENGTH,
      max_future_booking: MAX_FUTURE_BOOKING,
    });

    const i = incl.calculate(false);

    if (i.exclude_times.length === 0 && this.state.selected_bookable.user_vehicle === "0") {
      i.exclude_times = false;
    }

    // patching this functionally until I can replace that class
    let end_picker = () => {
      var duration_limit = moment(this.state.date_start).add(MAX_BOOKING_LENGTH, "days");
      let mfb = moment().add(MAX_FUTURE_BOOKING, "months");

      // find the next unavailable time after start
      var valid_thru = moment(this.state.date_start);
      if (vehicleAvails.length > 0) {
        // iterate from start time through dates for up to a week
        let end_time = moment(this.state.date_start).add(7, "days");
        for (var i = moment(this.state.date_start); i.isBefore(end_time); i.add(1, "days")) {
          var day_start_time = i.format("HH:mm:ss");
          var day_end_time = "23:59:59";

          valid_thru = day_start_time;

          var e = vehicleAvails
            .filter((ev) => ev.daysOfWeek == i.day())
            .sort((a, b) => b.startTime - a.startTime)
            .forEach(function (ev) {
              if (ev.startTime <= valid_thru && ev.endTime > valid_thru) {
                valid_thru = ev.endTime;
              }
            });

          if (valid_thru < day_end_time) {
            let vta = valid_thru.split(":");
            valid_thru = moment(i).set("hour", vta[0]).set("minute", vta[1]).set("second", vta[2]);
            break;
          }

          i.set("hour", 0).set("minute", 0).set("second", 0);
        }
      } else {
        valid_thru = mfb;
      }

      // find the next booked time after start and store the time it starts at
      var unbooked_thru = moment(this.state.date_start);
      let ut_start_time = moment(this.state.date_start).format("YYYY-MM-DD HH:mm:ss");
      var e = events ? events.filter((e) => e.start > ut_start_time && e.id != editing_event_id).sort((a, b) => b.start - a.start) : [];
      if (e.length > 0) {
        unbooked_thru = moment(e[0].start);
      } else {
        unbooked_thru = mfb;
      }

      // take the least of these cutoffs + apply it
      let latest_valid_time = moment.min([valid_thru, unbooked_thru, duration_limit, mfb]);

      // return any time from the selected end date that is either:
      // before the selected start time, or
      // after the latest valid time
      var times = [];
      var start_time = moment(this.state.date_start);
      var t = moment(this.state.date_end).hour(0).minute(0).second(0);
      var end_time = moment(t).add(1, "days");
      for (var t = moment(this.state.date_end).hour(0).minute(0).second(0); t.isBefore(end_time); t.add(15, "minutes")) {
        if (t.isBefore(start_time)) times.push(t.toDate());
        if (t.isAfter(latest_valid_time)) times.push(t.toDate());
      }

      // return dates from start to end
      let dates = [];

      for (var t = moment(this.state.date_start); t.isSameOrBefore(latest_valid_time, "day"); t.add(1, "day")) {
        dates.push(t.toDate());
      }

      return [times, dates];
    };

    let [end_times, end_dates] = end_picker();

    this.setState({
      include_dates_end: end_dates,
      exclude_times_end: end_times,
      exclude_times: d.exclude_times,
      include_times: i.exclude_times,
      date_end: d.date_end.toDate(),
    });
  }

  drawBookingEditor() {
    const { user, users, bookables, show, handleCancel, editingEvent } = this.props;
    const { date_start, date_end } = this.state;
    const duration = moment.duration(this.state.duration, "minutes").asHours();
    const sel_user = this.state.selected_user;
    // const hours_till_start = moment(date_start).diff( moment(), 'hours' );
    const is_past = moment(date_end).diff(moment(), "minutes") < 0;
    const allow_cancel = user.permission.includes("ADMIN_USER") || (sel_user.id === user.id && !is_past);

    return (
      <span>
        <ModalBody style={{ backgroundColor: "#ddd" }}>
          {users && user.permission.includes("ADMIN_USER") && (
            <Row style={{ marginBottom: 12 }}>
              <Col xs={2} style={{ textAlign: "right" }}>
                <FaUser style={{ fontSize: 26, color: "green", marginTop: 3 }} />
              </Col>
              <Col xs={10}>
                <InputGroup>
                  <Typeahead
                    id="username-typeahead"
                    labelKey="displayname"
                    placeholder="Choose a user..."
                    options={users}
                    selected={sel_user ? [sel_user.displayname] : null}
                    onChange={(selected) => {
                      if (selected[0]) {
                        const user = users.find((x) => x.id == selected[0].id);
                        this.setState({ selected_user: user }, () => this.validate());
                      }
                    }}
                    minLength={2}
                    ref={(ref) => (this._userchooser = ref)}
                  />

                  <FaTimesCircle onClick={() => this.clearUser()} className="username-clear"></FaTimesCircle>
                </InputGroup>
              </Col>
            </Row>
          )}

          <FormGroup>
            {this.props.calendar_clicked && this.state.selected_bookable && (
              <Card className="vehicle-badge">
                <CardBody>
                  <Row>
                    <Col xs={2}>
                      <FaCarAlt style={{ fontSize: 25, marginTop: 6, color: this.state.selected_bookable.color }} />
                    </Col>
                    <Col xs={8}>
                      <strong>{this.state.selected_bookable.name}</strong>
                      <br />
                      {this.state.selected_bookable.address}, {this.state.selected_bookable.city}
                    </Col>

                    <Col xs={1}>
                      {!this.state.showMap && (
                        <FaMapMarkedAlt
                          style={{ color: "#333", fontSize: 28, cursor: "pointer" }}
                          onClick={this.toggleMap}
                          title="Show position on map"
                        />
                      )}
                    </Col>
                  </Row>
                  {this.state.showMap && (
                    <Row>
                      <Col xs={12}>
                        <div style={{ height: 300, width: "100%", position: "relative" }}>
                          <div style={{ position: "absolute", right: 0, top: 0, zIndex: 999999, backgroundColor: "white", borderRadius: 20 }}>
                            <FaTimesCircle onClick={this.toggleMap} title="Hide map" style={{ fontSize: 35, color: "black", cursor: "pointer" }} />
                          </div>
                          <MiniMap
                            google={this.props.google}
                            zoom={17}
                            initialCenter={{ lat: this.state.selected_bookable.lat, lng: this.state.selected_bookable.lng }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            )}

            {!this.props.calendar_clicked && (
              <span>
                <BookableChooser bookables={this.props.bookables} selected_bookable={this.state.selected_bookable} handleChange={this.handleChange} />
                <Alert color="info" style={{ marginTop: 10 }}>
                  <FaInfoCircle /> &nbsp; <strong>Tip:</strong> you can book a vehicle by clicking on any blank spot on the calendar
                </Alert>
              </span>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="start-datetime">Starting</Label>
            <div>
              <DatePicker
                selected={this.state.date_start}
                onChange={this.handleStartDateChange}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMM d, yyyy h:mm aa"
                id="start-datetime"
                className="form-control"
                excludeTimes={this.state.exclude_times}
                includeTimes={this.state.include_times}
                onFocus={(e) => (e.target.readOnly = true)}
                minDate={this.props.user.permission.includes("ADMIN_USER") ? null : new Date()}
                maxDate={moment().add(MAX_FUTURE_BOOKING, "months").toDate()}
                disabled={(editingEvent && !allow_cancel) || !this.state.selected_bookable}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="end-datetime">Ending</Label>
            <div>
              <DatePicker
                selected={this.state.date_end}
                onChange={this.handleEndDateChange}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMM d, yyyy h:mm aa"
                id="end-datetime"
                className="form-control"
                includeDates={this.state.include_dates_end}
                includeTimes={this.state.include_times}
                excludeTimes={this.state.exclude_times_end}
                onFocus={(e) => (e.target.readOnly = true)}
                disabled={(editingEvent && !allow_cancel) || !this.state.selected_bookable}
              />
            </div>
          </FormGroup>
          {!editingEvent && this.state.dates_valid && (
            <Card>
              <Row>
                <Col xs={2} style={{ paddingTop: 6, paddingLeft: 18 }}>
                  <AppSwitch
                    className={"mx-1"}
                    color={"primary"}
                    variant={"pill"}
                    checked={this.state.recurring}
                    size={"sm"}
                    onChange={this.toggleRecurring}
                  />
                </Col>
                <Col xs={10} style={{ paddingTop: 6 }}>
                  {!this.state.recurring && <span>Not recurring</span>}

                  {this.state.recurring && (
                    <span>
                      Recurring weekly for &nbsp;
                      <select value={this.state.recurring_weeks} onChange={this.handleRecurringChange}>
                        {[2, 3, 4, 5, 6].map((e, key) => {
                          return (
                            <option value={e} key={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp; weeks
                      <p style={{ lineHeight: 1, marginTop: 4 }}>
                        <small>
                          Note: once you have created a recurring booking you will only be able to edit or delete each resulting booking individually.
                        </small>
                      </p>
                    </span>
                  )}
                </Col>
              </Row>
            </Card>
          )}

          <Row>
            <Col xs={12}>
              {Object.keys(this.state.validation_messages).map((e, key) => {
                let msg = this.state.validation_messages[e];
                return (
                  <Alert color="warning" key={key}>
                    <Row>
                      <Col xs={2}>
                        <FaExclamationTriangle style={{ fontSize: 24, marginTop: 8 }} />
                      </Col>
                      <Col xs={10}>{msg}</Col>
                    </Row>
                  </Alert>
                );
              })}

              <FormGroup>
                <Label htmlFor="kms">Estimated Kilometers</Label>
                <Input
                  type="text"
                  id="kms"
                  value={this.state.kms}
                  onChange={this.handleChange}
                  style={{ width: 80 }}
                  onFocus={this.HandleFocus}
                  disabled={!this.state.selected_bookable}
                />
              </FormGroup>

              {Object.keys(this.state.instruction_messages).map((e, key) => {
                let msg = this.state.instruction_messages[e];
                return (
                  <Alert color="info" key={key}>
                    {msg}
                  </Alert>
                );
              })}
            </Col>
          </Row>

          <FormGroup>
            <Label htmlFor="notes">Notes</Label>
            <Input type="text" id="notes" required value={this.state.notes} onChange={this.handleChange} disabled={!this.state.selected_bookable} />
          </FormGroup>

          {!editingEvent && (
            <div>
              <Button color="default" size="sm" onClick={() => this.props.changeState("complaint")}>
                I Couldn't get what I wanted
              </Button>
            </div>
          )}
        </ModalBody>

        <ModalBody>
          {this.state.kms > 0 && this.state.duration > 0 && (
            <div>
              <TripEstimator
                bookable={this.state.selected_bookable}
                kms={this.state.kms}
                duration={duration}
                member_type={this.getMemberType()}
                start_time={this.state.date_start}
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {editingEvent && (
            <span>
              {allow_cancel && (
                <Button size="sm" color="danger" onClick={this.handleCancelBooking} style={{ marginRight: 15 }}>
                  Cancel Booking
                </Button>
              )}
              {!allow_cancel && !user.permission.includes("ADMIN_USER") && (
                <Badge className="booking-cancel-warning">
                  <FaLock /> Cancellation not allowed
                </Badge>
              )}
            </span>
          )}
          <Button size="sm" color="secondary" onClick={handleCancel} style={{ marginRight: 20 }}>
            {editingEvent ? "Close" : "Cancel"}
          </Button>
          <Button size="lg" color="primary" onClick={this.handleSave} disabled={this.state.validation_messages.length > 0}>
            {editingEvent ? "Save" : "Create booking"}
          </Button>
        </ModalFooter>
      </span>
    );
  }

  drawVehicleNotices() {
    const { bookables, handleCancel, editingEvent, announcements } = this.props;
    return (
      <span>
        <ModalBody>
          <Card>
            <CardHeader className="bg-success">
              <FaCheckCircle /> All set! Your booking was saved successfully.
            </CardHeader>

            <CardBody>
              {/* todo: car icon */}

              <Row>
                <Col xs={4}>
                  <strong>{this.state.selected_bookable.nickname}</strong>
                </Col>
                <Col xs={8}>
                  <span className="nobr">
                    <strong>{moment(this.state.date_start).format("MMM Do YYYY, h:mm a")}</strong> &nbsp;{" "}
                  </span>
                  <span className="nobr">
                    to <strong>{moment(this.state.date_end).format("MMM Do YYYY, h:mm a")}</strong>
                  </span>
                  {this.state.recurring && (
                    <div>
                      <small>Recurring weekly for {this.state.recurring_weeks} weeks</small>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12} style={{ marginTop: 12 }}>
                  <FaMapMarkerAlt /> {this.state.selected_bookable.address}
                </Col>
                {/* todo: map, link to send to phone */}
              </Row>
            </CardBody>
          </Card>

          {announcements[this.state.selected_bookable.id] && (
            <Card>
              <CardHeader className="bg-dark">
                <FaInfoCircle /> About this vehicle:
              </CardHeader>
              {Object.keys(announcements[this.state.selected_bookable.id]).map((e, key) => {
                const a = announcements[this.state.selected_bookable.id][e];
                return <CardBody key={key}>{a.text}</CardBody>;
              })}
            </Card>
          )}
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" onClick={handleCancel}>
            Done
          </Button>
        </ModalFooter>
      </span>
    );
  }

  drawLog() {
    return (
      <span>
        <ModalBody className="event-log">
          <EventLog
            event={this.props.editingEvent}
            bookable={this.props.bookables[this.props.editingEvent.resourceId]}
            uid={this.props.uid}
            jwt={this.props.jwt}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" onClick={this.props.handleCancel}>
            Done
          </Button>
        </ModalFooter>
      </span>
    );
  }

  drawComplaintForm() {
    const { bookables, handleCancel } = this.props;

    return (
      <span>
        <ModalBody>
          <Card>
            <CardHeader className="bg-info">I couldn't find what I needed.</CardHeader>

            <CardBody>
              <p>Please choose the vehicle you needed and leave any comments about your situation.</p>
              <BookableChooser bookables={bookables} selected_bookable={this.state.selected_bookable} handleChange={this.handleChange} />
              <Input
                type="textarea"
                name="comments"
                id="comments-input"
                rows="9"
                placeholder="Comments"
                style={{ marginTop: 12 }}
                value={this.state.comments}
                onChange={this.handleChangeComments}
              />
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          {this.state.complaint_saved && (
            <Alert color="success">
              <strong>Thanks!</strong> Your input has been saved.
            </Alert>
          )}
          {!this.state.complaint_saved && (
            <span>
              <Button color="default" size="small" style={{ marginRight: 30 }} onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.handleSaveComplaint}>
                Save
              </Button>
            </span>
          )}
        </ModalFooter>
      </span>
    );
  }

  render() {
    const { users, bookables, show, handleCancel, editingEvent } = this.props;

    let modal_title = editingEvent ? "Edit Booking" : "Book a Vehicle";
    modal_title = this.state.display_mode === "main" ? modal_title : "Modification Log";

    return (
      <Modal isOpen={show} className="modal-lg primary booking-modal" style={{ maxWidth: 646 }} id="booking-modal">
        <ModalHeader toggle={handleCancel}>
          {modal_title}
          {editingEvent && (
            <span>
              {this.state.display_mode === "main" && (
                <Button size="sm" onClick={() => this.changeDisplayState("log")} style={{ marginLeft: 50 }}>
                  <FaList /> Modification Log
                </Button>
              )}
              {this.state.display_mode === "log" && (
                <Button size="sm" onClick={() => this.changeDisplayState("main")} style={{ marginLeft: 50 }}>
                  <FaChevronLeft /> Booking
                </Button>
              )}
            </span>
          )}
        </ModalHeader>
        {this.state.display_mode === "main" && (
          <span>
            {this.props.modalState == "editing" && this.drawBookingEditor()}
            {this.props.modalState == "notices" && this.drawVehicleNotices()}
            {this.props.modalState == "complaint" && this.drawComplaintForm()}
          </span>
        )}
        {this.state.display_mode === "log" && this.drawLog()}

        {/* todo: realtime rideshare suggestions, if going out of town */}
      </Modal>
    );
  }
}

export default BookingModal;

const BookableChooser = ({ bookables, selected_bookable, handleChange }) => {
  return !bookables ? null : (
    <span>
      <Label for="bookable-chooser">Vehicle</Label>
      <Input type="select" id="bookable-chooser" required value={selected_bookable ? selected_bookable.id : ""} onChange={handleChange}>
        {!selected_bookable && (
          <option key="" value="">
            Choose a vehicle
          </option>
        )}
        {Object.keys(bookables).map((e, key) => {
          const b = bookables[e];
          return (
            <option key={key} value={b.id}>
              {b.name}
            </option>
          );
        })}
      </Input>
    </span>
  );
};
