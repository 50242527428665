import React from "react";
import cookie from "react-cookies";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import $ from "jquery";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const postData = {
      action: "getInvoicables",
      id: this.props.uid,
      jwt: this.props.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        data.sort((a, b) => {
          if (this.extractDate(a) > this.extractDate(b)) return -1;
          if (this.extractDate(a) < this.extractDate(b)) return 1;
        });
        this.setState({ invoices: data });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  extractDate(string) {
    const arr = string.split(".")[0].split("-").slice(-2);
    return arr[1] + arr[0]; // year first for sorting
  }

  render() {
    return (
      <div className="animated fadeIn container">
        <Row>
          <Col xs="12">
            <Card className="mt-4 mg-12">
              <CardHeader>My Invoices</CardHeader>
              <CardBody className="">
                <table>
                  <tbody>
                    {Object.keys(this.state.invoices).map((e, key) => {
                      const invoice = this.state.invoices[key];
                      return <Invoice key={`invoice_${key}`} data={invoice} uid={this.props.uid} />;
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const localHost = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST + "/" : "";
const Invoice = ({ data, uid }) => {
  const url = localHost + "fileWrapper.php?user=" + uid + "&file=" + data;
  return (
    <tr>
      <td>
        <a href={url} target="_blank">
          {data}
        </a>
      </td>
    </tr>
  );
};

export default Invoices;
