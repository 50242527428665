import React from 'react';
import cookie from 'react-cookies';
import Axios from 'axios';

// import { Form, FormControl, Button, Panel} from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Alert } from 'reactstrap';
import { FaUser, FaLock } from 'react-icons/fa';

import $ from 'jquery';



const INVALID_LOGIN = ["The username or password you entered is invalid. Please try again."];


class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			mode: 'login',
			email: '',
			password: '',
			is_submitted: false,
			message: null,
			recover_last_name: '',
			recover_email: '',
			invalid_recover: false,
			recover_submitted: false
		}

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeypress = this.handleKeypress.bind(this);
	}




	handleSubmit(e){
		let self = this;
		const postData = {
			email: self.state.email,
			password: self.state.password,
			action: 'login',
			lastaction: 'display'
		};

		$.post( cookie.load('url') +'api.php', postData, function(data){
			data = JSON.parse(data);
			if(data.result == 'success'){
				self.props.setJWT(data);
			}
			else
				self.setState({ message : INVALID_LOGIN }, () => self.props.logOut() );
		} );

		this.setState({is_submitted: true})
	}



	handleRecoverSubmit(e){
		const postData = {
			email: this.state.recover_email,
			last_name: this.state.recover_last_name,
			action: 'resetPassword'
		};

		$.post( cookie.load('url') +'api.php', postData, (data)=>{
			data = JSON.parse(data);
			if(data.result == 'success')
				this.setState({ mode: 'login', recover_submitted: true, invalid_recover: false})
			else
				this.setState({ invalid_recover: true });
		} );

		this.setState({is_submitted: true})
	}


	handleChange(e){
		const data = { name: e.target.name, value : e.target.value };
		this.setState({[data.name] : data.value})
	}

	handleChangeMode(mode){
		this.setState({mode});
	}

	handleKeypress(e) {
		// enter key submits
		if (e.keyCode === 13){
			this.handleSubmit(e);
		}
	}

	render() {

		return(

			<div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                {this.state.mode === 'login' && (
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      {this.state.message && <Alert color="warning">{this.state.message}</Alert>}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaUser/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 

                        	type="text" 
							placeholder="Enter your username" 
							value={this.state.email} 
							name='email'
							onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaLock/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                        	type="password" 
							placeholder="Password" 
							value={this.state.password} 
							name='password'
							onChange={this.handleChange}
							onKeyUp={this.handleKeypress}
                        />
                      </InputGroup>
                      {this.state.recover_submitted && (
                      	<Alert color="info">Check your email inbox for your reset password, then use that to log in.</Alert>
                      )}
                      <Row>
                        <Col xs="6">
                          <Button
                          	onClick={this.handleSubmit} 
                          	color="primary" 
                          	type='button' 
                          	className="px-4"
                          >
                          	Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                        {!this.state.recover_submitted && (
                          <Button color="link" className="px-0" onClick={()=>this.handleChangeMode('recover')}>Forgot password?</Button>
                        )}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                  )}


                  {this.state.mode === 'recover' && (
                  	<CardBody>
                    <Form>
                      <h3>Lost Password</h3>
                      <p className="text-muted">Enter your username and last name to reset your password</p>
                      {this.state.message && <Alert color="warning">{this.state.message}</Alert>}
                      <InputGroup className="mb-3">
                        <Input 
                        	type="text" 
							placeholder="last name" 
							value={this.state.recover_last_name} 
							name='recover_last_name'
							onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">

                        <Input 
                        	type="text" 
							placeholder="Username" 
							value={this.state.recover_email}
							name='recover_email'
							onChange={this.handleChange}
                        />
                      </InputGroup>
                      {!this.state.invalid_recover && (
                       <Alert color="warning">Please verify that your last name and username are entered exactly as they are in your account. Clicking Reset will change your account password to a new random password and email this password to you.</Alert>
                      )}
                      {this.state.invalid_recover && (
                      	<Alert color="danger">I can't find an account with that username.</Alert>
                      )}
                      <Row>
                        <Col xs="6">


                          <Button
                          	onClick={()=>this.handleRecoverSubmit()} 
                          	color="primary" 
                          	type='button' 
                          	className="px-4"
                          >
                          	Reset
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" onClick={()=>this.handleChangeMode('login')}>Cancel</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>

                  )}
                </Card>
                <Card className="text-white py-5 d-md-down-none login-block" style={{ width: '44%', backgroundColor: '#000' }}>
                  <CardBody className="text-center">
                    <div>
                      <img src={'https://res.cloudinary.com/chaoscreative/image/upload/'+this.props.tenant+'_logo1.png'} style={{marginTop:20, width: '80%'}}/>
                      

                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>

		)



	/*	return(
			<Form>
				<Form.Group controlId="formEmail">
					<Form.Label>Username</Form.Label>
					<Form.Control 
						type="email" 
						placeholder="Enter your email" 
						value={this.state.email} 
						name='email'
						onChange={this.handleChange}/>
				</Form.Group>

				<Form.Group controlId="formPassword">
					<Form.Label>Password</Form.Label>
					<Form.Control 
						type="password" 
						placeholder="Password" 
						value={this.state.password} 
						name='password'
						onChange={this.handleChange}/>
				</Form.Group>

				<Button variant='primary' type='button' onClick={this.handleSubmit}>Submit</Button>
			</Form>

		) */

	}

}

export default Login;