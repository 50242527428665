import React from "react";
import "./scss/app.scss";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { LinkContainer } from "react-router-bootstrap";

import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Alert,
} from "reactstrap";

import cookie from "react-cookies";

import Login from "./partials/Login.jsx";
import Account from "./partials/Account.jsx";
import AirCarshare from "./partials/AirCarshare";
import Invoices from "./partials/Invoices.jsx";
import Dashboard from "./partials/Dashboard.jsx";
import Admin from "./partials/Admin.jsx";
import VehicleAvails from "./partials/VehicleAvails";

import $ from "jquery";
import "./App.scss";
import "@coreui/coreui";

import { api } from "./shared/functions/api";
import logo from "./assets/img/brand/kcc_logo1.png";

const CLOUDINARY_PATH = "https://res.cloudinary.com/chaoscreative/image/upload/";
let TENANT = "main";
let WEBSITE = "https://carsharecoop.ca";

export const UserContext = React.createContext(null);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: cookie.load("jwt"),
      dev_mode: false,

      menu_is_open: false,
      user_save_success: null,
      loaded: false,
    };
    this.toggle_menu = this.toggle_menu.bind(this);
    this.setJWT = this.setJWT.bind(this);
    this.init = this.init.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);

    this.setTenant();
  }

  get expiryTime() {
    return Date.now() + 1000 * 60 * 60 * 24 * 13; // two weeks less a day
    // return Date.now() + 1000*60*60*2; // two hours
  }

  get jwtExpired() {
    let expiry = new Date(parseInt(cookie.load("jwtExpiry")));
    return Date.now() > expiry;
  }
   //http://192.168.106.2/  is developer Dallas Larsen's local docker development environment reachable IP om MacOS
  // running docker from Colima container run times setup , https://github.com/abiosoft/colima

  // TODO: move this to .env file so it can be set per environment
  componentDidMount() {
    // cookie.save("url", window.location.href.includes("carsharecoop.ca/") ? "" : "http://192.168.106.2/");
    cookie.save("url", window.location.href.includes("carsharecoop.ca/") ? "" : "http://work/"); // Kevin's setup
    this.init();
    this.checkTokenListener();
    this.setFavicon();
  }

  setFavicon() {
    let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');

    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }

    link.href = `${CLOUDINARY_PATH}favicon-${TENANT}.ico`;
  }

  setJWT(data) {
    const jwtLib = require("jsonwebtoken");
    const jwt_payload = jwtLib.decode(data.jwt);
    this.setState(
      {
        jwt: data.jwt,
        uid: data.uid,
        permission: jwt_payload.permission,
      },
      () => {
        cookie.save("jwt", data.jwt);
        cookie.save("uid", data.uid);
        cookie.save("jwtExpiry", this.expiryTime);

        this.loadUser();
        this.loadCalendarsBookables();
      }
    );
  }

  init() {
    let jwt = null;
    if ((jwt = cookie.load("jwt"))) {
      if (this.jwtExpired) {
        this.logOut();
        return;
      }

      let uid = null;
      this.setJWT({ jwt: jwt, uid: cookie.load("uid") });
    }
  }

  setTenant() {
    const tenants = {
      members: { code: "main", website: "https://carsharecoop.ca" },
      gospark: { code: "spark", website: "http://gospark.ca" },
    };

    const url_bits = window.location.host.split(".");
    var i = url_bits.indexOf("www");
    if (i > -1) url_bits.splice(i, 1);
    if (url_bits.length > 1) {
      // not local dev env
      TENANT = tenants[url_bits[0]].code;
      WEBSITE = tenants[url_bits[0]].website;
    }
  }

  checkToken() {
    if (!this.state.jwt) return false;

    const postData = {
      action: "checkToken",
      id: this.state.uid,
      jwt: this.state.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        cookie.save("jwtExpiry", this.expiryTime);
      })
      .fail((err) => {
        this.logOut();
        console.log("err", err);
      });
  }

  checkTokenListener() {
    setInterval(() => {
      this.checkToken();
    }, 30000);
  }

  /*
       getSession() {
        $.getJSON('http://localhost/api.php?action=checkSession&jwt=' + this.state.jwt, (data) => {
          let session = (this.state.dev_mode) ? dev_session : data;

          if(session.permission)
            session.permission = session.permission.split(',');
          else
            session = {};
          console.log(session);
          if(session.uid)
            this.setState({session: session}, ()=>{
              this.loadUser();
              this.loadCalendarsBookables();
            });
          else
            console.log("ERROR in App.js getSession() : no UID");
        });
      }

    */

  async loadUser() {
    const userObjectResponse = await api(`users/view/${this.state.uid}.json`);

    const userObject = userObjectResponse.User;

    if (userObject && !userObject.error) {
      userObject.permission = this.state.permission;
      userObject.vehicles = userObjectResponse.Vehicle;

      if (userObject.resource_filter) {
        userObject.resource_filter = JSON.parse(userObject.resource_filter);
      }
      this.setState({ user: userObject });
    } else {
      this.logOut();
      alert(userObject && userObject.error);
    }
  }

  loadCalendarsBookables() {
    const postData = {
      action: "getCalendarBookables",
      id: this.state.uid,
      jwt: this.state.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        this.setState({ calendars: JSON.parse(response) });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  handleSaveUser(user) {
    const postData = {
      action: "saveUser",
      user: user,
      id: this.state.uid,
      jwt: this.state.jwt,
    };

    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        this.loadUser();
        if (data.result == "success") this.setState({ user_save_success: true });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  toggle_menu() {
    this.setState({
      menu_is_open: !this.state.menu_is_open,
    });
  }

  logOut() {
    let self = this;
    this.setState({
      user: null,
      uid: null,
      jwt: null,
    });
    cookie.remove("jwt");
    cookie.remove("uid");
    cookie.remove("jwtExpiry");

    const postData = {
      action: "logOut",
      id: this.state.uid,
      jwt: this.state.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {})
      .fail((err) => {
        console.log("err", err);
      });

    // todo: also post logout to server so session gets destroyed
  }

  render() {
    let user = this.state.user || {};
    user.permission = this.state.user ? this.state.user.permission : null;
    return (
      <UserContext.Provider value={user}>
        <Router>
          <div className="app">
            {this.state.user && (
              <header className="app-header">
                <div>
                  <Navbar color="dark" dark expand="sm" style={{ minHeight: 80 }}>
                    <NavbarBrand href={WEBSITE}>
                      <img src={CLOUDINARY_PATH + TENANT + "_logo1.png"} className="logo" />
                    </NavbarBrand>

                    <NavbarToggler onClick={this.toggle_menu} />
                    <Collapse isOpen={this.state.menu_is_open} navbar>
                      <Nav className="ml-md-auto" navbar>
                        <NavItem>
                          <Link to="/">
                            <strong>Dashboard</strong>
                          </Link>
                        </NavItem>
                        {this.state.permission && this.state.permission.includes("ADMIN_USER") && (
                          <NavItem>
                            <Link to="/administration">Administration</Link>
                          </NavItem>
                        )}

                        {this.state.user && (
                          <UncontrolledDropdown nav>
                            {/*Warning: React does not recognize the `inNavbar` prop on a DOM element.*/}
                            {/*waiting for reactstrap@5.0.0-alpha.5*/}
                            <DropdownToggle nav caret>
                              {this.state.user.displayname.trim()}
                            </DropdownToggle>
                            <DropdownMenu right>
                              <Link to="/account">
                                <DropdownItem>Account</DropdownItem>
                              </Link>

                              {user.vehicles.length > 0 && (
                                <Link to="/vehicleavails">
                                  <DropdownItem>My Vehicles</DropdownItem>
                                </Link>
                              )}

                              <Link to="/invoices">
                                <DropdownItem>Invoices</DropdownItem>
                              </Link>

                              <Link to="/" onClick={this.logOut}>
                                <DropdownItem>Sign out</DropdownItem>
                              </Link>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </Nav>
                    </Collapse>
                  </Navbar>
                </div>
              </header>
            )}

            <div className="app-body">
              <main className="main">
                {!this.state.user && !this.state.jwt && <Login tenant={TENANT} setJWT={this.setJWT} logOut={this.logOut} />}
                {this.state.jwt && this.state.uid && (
                  <div>
                    <Route
                      path="/administration"
                      render={(props) => <Admin uid={this.state.uid} jwt={this.state.jwt} calendars={this.state.calendars} />}
                    />

                    <Route
                      path="/account"
                      render={(props) => (
                        <Account
                          user={this.state.user}
                          handleSave={this.handleSaveUser}
                          uid={this.state.uid}
                          jwt={this.state.jwt}
                          saveSuccess={this.state.user_save_success}
                        />
                      )}
                    />

                    <Route path="/aircarshare" render={(props) => <AirCarshare user={this.state.user} />} />

                    <Route path="/vehicleavails" render={(props) => <VehicleAvails user={this.state.user} />} />

                    <Route path="/invoices" render={(props) => <Invoices uid={this.state.uid} uid={this.state.uid} jwt={this.state.jwt} />} />

                    <Route
                      exact
                      path="/"
                      render={(props) => <Dashboard calendars={this.state.calendars} uid={this.state.uid} jwt={this.state.jwt} />}
                      context={UserContext}
                    />
                  </div>
                )}
              </main>
            </div>
          </div>
        </Router>
      </UserContext.Provider>
    );
  }
}

export default App;
