import React, { useState, useEffect } from 'react';
import cookie from "react-cookies";
import { Alert, Badge, Button, Card, Container, Table, CardBody, CardHeader, CardText, Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import {api} from "../shared/functions/api"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import $ from "jquery";
import {
  FaLongArrowAltRight,
  FaFileDownload,
  FaEdit,
  FaCarAlt,
  FaUserAlt,
  FaFileInvoiceDollar,
  FaRegCalendarAlt,
  FaList,
  FaFileUpload,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaCaretRight,
  FaCaretDown,
} from "react-icons/fa";
import { get } from 'http';

const ADMIN_PATH = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST + "/admin/cake210/" : "/admin/cake210/";

const styles = {
  th: { backgroundColor: "#333", color: "white", padding: 8, border: "0px"},
  td: { backgroundColor: "#eee", padding: 8 },
  td_selected: { backgroundColor: "#eee", padding: 8, border: "0px" },
};

const warnings = {
  noAvailability: "This vehicle has no availability set, so nobody is able to book it. Please contact the owner and advise them to log in and set availability.",
  noNotifications: "This user has no notifications set, so they will not be notified when their vehicle is booked."
}

//console.log(process.env.REACT_APP_HOST);
const AirCarshare = (props) => {

  const [ vehicles, setVehicles ] = useState(null);
  const [ selected, setSelected ] = useState(null);

  useEffect(() => {
    loadVehicles();
  }, []);

  async function loadVehicles() {
    const vehicles = await api(`aircarshare/vehicles`, `GET`)
    setVehicles(vehicles);
     // console.log(vehicles);
  }

  function selectVehicle(id) {
    console.log(id);
    setSelected((selected === id) ? null : id);
  }

  return (vehicles) ?
      (
        <div className="animated fadeIn container">
          <Row style={{ marginTop: 30, marginBottom: 0 }}>
            <Col xs={12}>
              <Card color="primary">
                <CardHeader style={{backgroundColor: '#7fcd6d', border: 'green', color: 'black'}}>
                  <Row>
                    <Col xs={8} style={{ marginBottom: 10 }}>
                      <h4>Air Carshare Program</h4>
                    </Col>
                    <Col xs={4}>
                      <div className="float-right">
                        <Badge color="dark">
                        {vehicles.length} Vehicles
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{backgroundColor: '#c2c2c2', border: 'green', color: 'black'}}>
                    <Table>
                      <thead>
                      <tr>
                        <th style={styles.th}>Status</th>
                        <th style={styles.th}>Owner</th>
                        <th style={styles.th}>Vehicle</th>
                        
                      </tr>
                      </thead>
                      {vehicles.map((item) => {
                        const key = vehicles.indexOf(item);
                        return <Vehicle 
                                  key={key} 
                                  _key={key}
                                  item={item}
                                  selected={(selected === key)}
                                  select={setSelected}
                                />;
                      })}
                 
                    </Table>

                  <Row>
                    <Col xs={12}>
                      <CardText >
                        <small>
                          <strong>Click above</strong> to reveal more information about each vehicle / owner.
                          <br/><strong>Status:</strong> <Badge color='success'>√</Badge> = Vehicle is available for booking, <Badge color='danger'>!</Badge> = Vehicle is not available for booking.
                          <br/><strong>Availibility:</strong> the user-entered time slots when the vehicle is available for booking. Without these, it cannot be booked.
                          <br/><strong>Notifications:</strong> how/whether the user will be notified when their vehicle is booked.
                        </small>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
                   
              </Card>
            </Col>
          </Row>
        </div>
      ) : <Alert>Loading...</Alert>;
}

const Vehicle = ({item, select, selected, _key}) => {
  const vehicle = item.Vehicle;
  const user = item.User[0];
  const availability = getAvailabilities(item.vehiclesavailability);
  const notifications = getNotifications(user.notifications);

  return (
    <tbody onClick={()=>select(_key)}>
    <tr id={vehicle.id}>
        <td style={styles.td}>
          {availability.length < 1 ? <Badge color='danger'>!</Badge> : <Badge color='success'>√</Badge>}
        </td>
        <td style={styles.td}>
          {user.displayname.split(" --")[0]}
        </td>
        <td style={styles.td}>
          <Badge>{vehicle.id}</Badge> {vehicle.nickname}
        </td>
    </tr>
      {selected && (
          <tr id={vehicle.id}>
            <td style={styles.td_selected}>
            </td>
            <td style={styles.td_selected}>
              <UserStatus user={user} notifications={notifications}/>
            </td>
            <td style={styles.td_selected}>
              <Availability availability={availability}/>
            </td>
          </tr>
      )}
    </tbody>
  
  );
}


function getAvailabilities(a){
  if (!a) return [];
  a = a.filter((item, index) => item.startTime !== '00:00:00');
  if (!a) return [];
  const days = {1: "Sun", 2: "Mon", 3: "Tue", 4: "Wed", 5: "Thu", 6: "Fri", 7: "Sat"};
  const list = [];
  for (let i=1; i<8; i++) {
    const day = days[i];
    const items = a.filter((item) => item.daysOfWeek === i.toString());
    if (items.length > 0) {
      let times = [];
      for (let j=0; j<items.length; j++) {
        times.push(items[j].startTime + " - " + items[j].endTime);
      }
      list.push( day + ": " + times.join(", "));
    }
  }
  return list;
}

function getNotifications(n){
  if (!n) return [];
  const list = [];
  if (n.notification_email === "true") list.push("Email");
  if (n.notification_text === "true") list.push("Text");
  return list;
}



const Availability = ({availability}) => {
  return availability.length ? (
    <span><strong>Availability:</strong> {availability.map((a,i)=>(<div key={i}>{a}</div>))}</span>
  ) : <Badge color='danger' title={warnings.noAvailability}>No Availability</Badge>;
}

const UserStatus = ({user, notifications}) => {
  
  return (
    <span>
      <strong>Notifications:</strong> {notifications.length ? notifications.join(", ") : <Badge color='danger' title={warnings.noNotifications}>None</Badge>}
    </span>
  )
}

export default AirCarshare;
