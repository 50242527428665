import React from "react";
import cookie from "react-cookies";
import { Alert, Badge, Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import $ from "jquery";
import {
  FaLongArrowAltRight,
  FaFileDownload,
  FaEdit,
  FaCarAlt,
  FaCarSide,
  FaUserAlt,
  FaFileInvoiceDollar,
  FaRegCalendarAlt,
  FaList,
  FaFileUpload,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaCaretRight,
  FaCaretDown,
} from "react-icons/fa";

const ADMIN_PATH = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST + "/admin/cake210/" : "/admin/cake210/";
//console.log(process.env.REACT_APP_HOST);
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceProcessOpen: false,
      booking_stats: null,
      user_stats: null,
      alerts: null,
      exporting: false,
      export_start: new Date(),
      export_end: new Date(),
      export_bookings: null,
      export_calendar: 1,
    };

    this.toggleInvoiceProcess = this.toggleInvoiceProcess.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.handleExportStartChange = this.handleExportStartChange.bind(this);
    this.handleExportEndChange = this.handleExportEndChange.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.clearBookings = this.clearBookings.bind(this);
  }

  componentDidMount() {
    this.loadAdminInfo();
  }

  toggleInvoiceProcess() {
    //console.log(this.state.invoiceProcess);
    this.setState({ invoiceProcessOpen: !this.state.invoiceProcessOpen });
  }

  handleDismissAlert(alert) {
    //console.log(alert);
    const postData = {
      action: "dismissAlert",
      jwt: this.props.jwt,
      id: this.props.uid,
      alert_id: alert.id,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (!data.error) {
          let alerts = this.state.alerts;
          delete alerts[alert.id];
          this.setState({ alerts });
        }
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  handleExportStartChange(a) {
    this.setState({ export_start: a });
  }

  handleExportEndChange(a) {
    this.setState({ export_end: a });
  }

  handleCalendarChange(e) {
    this.setState({ export_calendar: e.target.value });
  }

  exportBookings() {
    console.log("gonna expoert naw");
    const postData = {
      action: "getBookingsExport",
      jwt: this.props.jwt,
      id: this.props.uid,
      calendar: this.state.export_calendar,
      start: moment(this.state.export_start).format("YYYY-MM-DD 00:00:00"),
      end: moment(this.state.export_end).format("YYYY-MM-DD 00:00:00"),
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (!data.error) {
          this.setState({ export_bookings: data });
        }
      })
      .fail((err) => {
        console.log("err", err);
      });
  }

  clearBookings() {
    this.setState(
      {
        export_bookings: null,
        export_complete: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ export_complete: false });
        }, 5000);
      }
    );
  }

  loadAdminInfo() {
    const postData = {
      action: "getAdminInfo",
      id: this.props.uid,
      jwt: this.props.jwt,
    };
    $.ajax({
      url: cookie.load("url") + "api.php",
      type: "POST",
      data: postData,
    })
      .done((response) => {
        const data = JSON.parse(response);
        if (!data.error)
          this.setState({
            booking_stats: data.booking_stats,
            user_stats: data.user_stats,
            alerts: data.alerts,
          });
      })
      .fail((err) => {
        console.log("err", err);
      });
  }


  

  toggleExportBookings() {
    this.setState({ exporting: !this.state.exporting });
  }

  render() {
    const calendar_title =
      this.state.export_calendar && this.props.calendars ? this.props.calendars[this.state.export_calendar].calendar_title : null;
    const filename = !this.state.export_bookings
      ? null
      : "bookings_" +
        calendar_title +
        "_" +
        moment(this.state.export_start).format("YYYY-MM-DD") +
        "_to_" +
        moment(this.state.export_end).format("YYYY-MM-DD") +
        ".csv";

    if (this.state.booking_stats)
      return (
        <div className="animated fadeIn container">
          <Row style={{ marginTop: 30, marginBottom: 0 }}>
            <Col xs={12}>
              <Card color="dark">
                <CardHeader>
                  <Row>
                    <Col xs={8} style={{ marginBottom: 10 }}>
                      <h4>Carshare Administration</h4>
                    </Col>
                    <Col xs={4}>
                      <div className="float-right">
                        Active users:{" "}
                        <Badge color="primary" style={{ color: "black" }}>
                          {this.state.user_stats}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <small>Bookings today:</small>
                      {Object.keys(this.state.booking_stats).map((e, key) => {
                        const area = this.state.booking_stats[e];
                        return (
                          <span className="area-stats-badge" key={key}>
                            <small>{e}</small> <Badge color="warning">{area}</Badge>
                          </span>
                        );
                      })}
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              {this.state.alerts && (
                <Card>
                  <CardBody>
                    {Object.keys(this.state.alerts).map((e, key) => {
                      const alert = this.state.alerts[e];
                      const link = alert.link ? (
                        <span>
                          {" "}
                          &nbsp;
                          <a href={alert.link}>
                            More info
                            <FaLongArrowAltRight />
                          </a>
                        </span>
                      ) : null;
                      return (
                        <Alert color="warning" className="alert-notice" key={key}>
                          <FaInfoCircle /> &nbsp;
                          {alert.message} on {alert.created_at.substring(0, 10)}
                          {link}
                          <Button close onClick={() => this.handleDismissAlert(alert)} />
                        </Alert>
                      );
                    })}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Card>
                <CardHeader style={{ backgroundColor: "#fe8899" }}>
                  <FaInfoCircle className="section-icon" /> Announcements
                  <Button size="sm" color="dark" className="float-right" href={ADMIN_PATH + "announcements"}>
                    <FaEdit /> Manage
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Button color="secondary" href={ADMIN_PATH + "announcementdates"}>
                        <FaRegCalendarAlt /> Announcement Dates
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader className="bg-blue">
                  <FaUserAlt className="section-icon" /> Users
                  <Button size="sm" color="dark" className="float-right" href={ADMIN_PATH + "users"}>
                    <FaEdit /> Manage
                  </Button>
                </CardHeader>
                <CardBody>
                  <Button href={ADMIN_PATH + "groups"}>
                    <FaEdit /> Manage Groups
                  </Button>
                </CardBody>
              </Card>

              <Card>
                <CardHeader style={{ backgroundColor: "#7fcd6d" }}>
                  <FaCarSide className="section-icon" /> Air Carshare
                  <Button size="sm" color="dark" className="float-right" href={"/aircarshare"}>
                    <FaEdit /> View
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      
                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </Col>
            <Col sm={6}>
              <Card>
                <CardHeader style={{ backgroundColor: "#fccf46" }}>
                  <FaRegCalendarAlt className="section-icon" /> Bookings
                  <Button size="sm" color="dark" className="float-right" href={ADMIN_PATH + "administerbookings"}>
                    <FaEdit /> Manage
                  </Button>
                </CardHeader>
                <CardBody>
                  {!this.state.exporting && (
                    <Button size="sm" onClick={() => this.toggleExportBookings()}>
                      <FaEdit /> Export
                    </Button>
                  )}
                  {this.state.exporting && (
                    <div>
                      <FormGroup>
                        <Label htmlFor="export-calendar">Calendar: &nbsp;</Label>
                        <select className="form-control" onChange={this.handleCalendarChange} id="export-calendar">
                          {Object.keys(this.props.calendars).map((key) => {
                            return (
                              <option value={key} key={key}>
                                {this.props.calendars[key].calendar_title}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                      <FormGroup>
                        <Label for="">From: &nbsp;</Label>
                        <DatePicker
                          selected={this.state.export_start}
                          onChange={this.handleExportStartChange}
                          dateFormat="MMM d, yyyy"
                          id="start-datetime"
                          className="form-control"
                          onFocus={(e) => (e.target.readOnly = true)}
                        />
                      </FormGroup>
                      <FormGroup>
                        To: &nbsp;
                        <DatePicker
                          selected={this.state.export_end}
                          minDate={this.state.export_start}
                          onChange={this.handleExportEndChange}
                          dateFormat="MMM d, yyyy"
                          id="end-datetime"
                          className="form-control"
                          onFocus={(e) => (e.target.readOnly = true)}
                        />
                      </FormGroup>
                      <Row>
                        <Col xs={3}>
                          <Button onClick={() => this.exportBookings()}>Export</Button>
                        </Col>
                        <Col xs={9}>
                          {this.state.export_bookings && (
                            <span>
                              <CSVLink data={this.state.export_bookings} target="_blank" filename={filename} onClick={this.clearBookings}>
                                <FaFileDownload />
                                Download CSV
                              </CSVLink>
                            </span>
                          )}

                          {this.state.export_complete && <Alert>File downloaded successfully. Check your download folder.</Alert>}
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardHeader className="bg-teal">
                  <FaCarAlt className="section-icon" /> Bookables
                  <Button size="sm" color="dark" className="float-right" href={ADMIN_PATH + "bookables"}>
                    <FaEdit /> Manage
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <Button href={ADMIN_PATH + "vehicles"}>
                        <FaCarAlt /> Vehicles
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button href={ADMIN_PATH + "locations"}>
                        <FaMapMarkerAlt /> Locations
                      </Button>
                    </Col>
                  </Row>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Card>
                <CardHeader style={{ backgroundColor: "#b4b4b4" }}>
                  <FaList className="section-icon" /> Logs
                  <Button size="sm" color="dark" className="float-right" href={ADMIN_PATH + "logs"}>
                    <FaList /> View
                  </Button>
                </CardHeader>
                <CardBody>
                  <Button href={ADMIN_PATH + "couldntbookcar"}>
                    <FaList /> Couldn't book logs
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <CardHeader className="bg-light-blue">
                  <FaFileInvoiceDollar className="section-icon" /> Billing
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <Button href={ADMIN_PATH + "listinvoices"}>
                        <FaList /> List invoices for user
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button href={ADMIN_PATH + "Chartofaccounts"}>
                        <FaFileUpload /> Upload accounts
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <br />
                      <Button onClick={this.toggleInvoiceProcess}>
                        Invoice Process
                        {this.state.invoiceProcessOpen ? <FaCaretDown /> : <FaCaretRight />}
                      </Button>
                      <Collapse isOpen={this.state.invoiceProcessOpen}>
                        <ListGroup>
                          <ListGroupItem tag="a" href={ADMIN_PATH + "billings"} action>
                            1. Create a billing object
                          </ListGroupItem>
                          <ListGroupItem tag="a" href={ADMIN_PATH + "invoiceextraitems"} action>
                            2. Add extra items to invoices
                          </ListGroupItem>
                          <ListGroupItem tag="a" href={ADMIN_PATH + "previewinvoice"} action>
                            3. Preview invoices
                          </ListGroupItem>
                          <ListGroupItem tag="a" href={ADMIN_PATH + "Generateinvoices/inv_list"} action>
                            4. Generate invoices, Create CSV files, email to members
                          </ListGroupItem>
                          <ListGroupItem>5. Remember to make a quickbooks backup so we can backout changes</ListGroupItem>
                          <ListGroupItem tag="a" href={ADMIN_PATH + "listinvoices"} action>
                            6. Downloadl CSV files for generated invoices
                          </ListGroupItem>
                          <ListGroupItem>
                            7. Import CSV file into quickbooks and check that items were distributed to correct accounts and correct invoices were
                            generated.
                          </ListGroupItem>
                          <ListGroupItem>
                            8. LOOK FOR UNUSUAL "bank" ACCOUNTS IN THE CHART OF ACCOUNTS. If these are present then there must be a mismatch between
                            accounts on the website and accounts in Quickbooks.
                          </ListGroupItem>
                          <ListGroupItem>
                            9. If one invoice does not import, check that the user is not also a 'Vendor' in Quickbooks. Quickbooks will not allow you
                            to invoice a vendor
                          </ListGroupItem>
                        </ListGroup>
                      </Collapse>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    else return <Alert>Loading...</Alert>;
  }
}

export default Admin;
