import React from "react";

import { Table, Badge, Card, CardBody, FormGroup, Input } from "reactstrap";

import moment from "moment";

const MAX_BOOKING_LENGTH = 120; // days

/*

- add cursor : pointer to calendar
- vehicle filter: show address

*/

const type_classes = {
  1: 3, // van midsize
  2: 3, // car midsize
  4: 2, // ??  trailer
  5: 2, // ?? accessory
  6: 2, // van economy
  7: 2, // car economy
  8: 3, // truck eco
  9: 4, // van large
  10: 4, // car large
  11: 4, // truck large
  12: 1, // electric car
};

class TripEstimator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      member_type: this.props.member_type || "full",
      showForm: false,
      km_cost: 0,
      time_cost: 0,
      total: 0,
      hours_full_rate: 0,
      hours_discount_rate: 0,
      daily_insurance_rate_p2p: parseFloat(this.props.bookable.daily_insurance_rate_p2p) || 0,
    };

    this.handleMemberTypeChange = this.handleMemberTypeChange.bind(this);
  }

  componentDidMount() {
    this.calculate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookable !== this.props.bookable || prevProps.kms !== this.props.kms || prevProps.duration !== this.props.duration)
      this.calculate();

    if (prevProps.member_type !== this.state.member_type) this.setState({ member_type: this.props.member_type });
  }

  handleMemberTypeChange(e) {
    this.setState({ member_type: e.target.value }, () => {
      this.calculate();
    });
  }

  calculate() {
    const km_cost = this.calculateKMCost();
    const time_cost = this.calculateTimeCost();
    const total = Math.round(km_cost + time_cost + this.state.daily_insurance_rate_p2p);
    this.setState({ km_cost, time_cost, total });
  }

  calculateKMCost() {
    const b = this.props.bookable;
    const rate = this.state.member_type === "full" ? b.km_rate_low : b.km_rate_high;
    return parseFloat(this.props.kms) * parseFloat(rate);
  }

  calculateTimeCost() {
    const b = this.props.bookable;
    let time_cost = 0;
    if (this.state.member_type === "casual") {
      time_cost = parseFloat(b.hourly_rate_high_casual) * this.props.duration;
    } else {
      const hours = this.getHourBreakdown();
      time_cost = hours.full * parseFloat(b.hourly_rate_high);
      let d = 0;
      if (hours.discount) {
        d = hours.discount * parseFloat(b.hourly_rate_low);
        time_cost = time_cost + d;
      }
      this.setState({ hours_full_rate: hours.full, hours_discount_rate: hours.discount });
    }
    return time_cost;
  }

  getHourBreakdown() {
    let time = moment(this.props.start_time);
    let result = { full: 0, discount: 0 };
    let discount_hours = [21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
    let full_rate_cutoff = 8;

    const hours = Math.floor(this.props.duration);
    const minutes = this.props.duration - hours;

    // console.log(discount_hours);

    for (let h = 0; h < hours; h++) {
      time.add(1, "hour");

      if (h >= full_rate_cutoff || discount_hours.includes(parseInt(time.format("H")))) result.discount++;
      else result.full++;

      // console.log(time.format('H'));
    }

    if (minutes) {
      if (discount_hours.includes(parseInt(time.format("H")))) result.discount += minutes;
      else result.full += minutes;
    }

    return result;
  }

  render() {
    const member_types = { full: "Full member", casual: "Casual member" };
    return (
      <Card className="trip-estimator" style={{ margin: "0% auto" }}>
        <CardBody>
          <span className="float-right">
            {!this.props.member_type && (
              <FormGroup>
                <Input type="select" name="member-type" id="member-type-field" value={this.state.member_type} onChange={this.handleMemberTypeChange}>
                  <option value="full">Full member</option>
                  <option value="casual">Casual member</option>
                </Input>
              </FormGroup>
            )}
            {this.props.member_type && <Badge>{member_types[this.props.member_type]}</Badge>}
          </span>
          <strong>Estimated Cost</strong>

          <Table className="estimate-table">
            <tbody>
              <tr>
                <th className="time-item">{this.props.duration} hrs</th>
                <th></th>
                <th className="distance-item">{this.props.kms} kms</th>
                <th></th>
                {this.state.daily_insurance_rate_p2p !== 0 && (
                  <>
                    <th className="distance-item">Insurance</th>
                    <th> </th>
                  </>
                )}
                <th> </th>
                <th>Total (est)</th>
              </tr>

              <tr>
                <td className="time-item">${Math.round(this.state.time_cost)}</td>
                <td>+</td>
                <td className="distance-item">${Math.round(this.state.km_cost)}</td>
                {this.state.daily_insurance_rate_p2p !== 0 && (
                  <>
                    <td>+</td>
                    <td>${Math.round(this.state.daily_insurance_rate_p2p)}</td>
                  </>
                )}
                <td> </td>
                <td>=</td>
                <th className="estimate-total">${this.state.total}</th>
              </tr>
            </tbody>
          </Table>
          <small className="estimate-footnote">
            Hours full rate: <strong>{this.state.hours_full_rate}</strong> Hours low rate: <strong>{this.state.hours_discount_rate}</strong>
          </small>
          <br />
          <small className="estimate-footnote">Total before taxes; $0.033/km added</small>
          <br />
          <small className="estimate-footnote">Gas surcharge may be applied</small>
        </CardBody>
      </Card>
    );
  }
}

export default TripEstimator;
